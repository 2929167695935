@use 'mixins';

.NewsBlockListItem {
    padding: var(--standardPadding);
    overflow: hidden;
    border-bottom: 1px solid rgb(var(--lightSectionBorder));

    a {
        display: contents;
        text-decoration: none;
        color: inherit;

        &:hover {
            color: inherit;
        }
    }

    .image {
        float: left;
        width: 6.625em;
        height: auto;
        margin-right: var(--standardPadding);
    }

    .counter {
        position: absolute;
        top: var(--standardPadding);
        left: var(--standardPadding);
    }

    .heading {
        margin: 0;
        padding: 0;

        @include mixins.row-limit(2);

        // When VI, hide meta and limit row to 3
        [data-theme='vi'] & {
            @include mixins.row-limit(3);
        }
    }

    .content {
        display: flex;
        align-content: space-between;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }

    .meta {
        @extend %caption-small;

        display: inline-block;
        min-height: var(--largePadding);
        line-height: 1em;
        color: rgb(var(--lighterTextColor));

        [data-theme='vi'] & {
            display: none;
        }

        > span {
            line-height: inherit;
            vertical-align: middle;
        }

        :global(.Icon) {
            margin-right: var(--tinyPadding);
            font-size: 1.65em;
            vertical-align: text-bottom;
        }
    }
}
