.Avatar {
    &.is-small {
        width: 1.75em;
        height: 1.75em;
    }

    &.is-rounded {
        border-radius: 50%;
    }
}
