@use 'mixins';

.Widget {
    display: block;
    position: relative;
    margin-bottom: var(--largePadding);
    padding: 0;
    z-index: 1;
    overflow: hidden;
    transition: all 0.5s ease-in-out;
    opacity: 1;

    --border-thickness: 2px;

    [data-theme='vi'] & {
        --border-thickness: var(--standardBorderWidth);
    }

    header {
        line-height: 3.5em;
        white-space: nowrap;

        h2 {
            line-height: var(--text-line-height);
        }

        [data-theme='vi'] & {
            a {
                color: rgb(var(--secondColor));
            }
        }
    }

    &-title {
        display: inline-block;
        white-space: nowrap;
    }

    &-list {
        display: block;
        margin: 0;
        padding: 0;
        list-style: none;
        border: 0;
    }

    .border {
        border-bottom: var(--border-thickness) solid rgb(var(--mainColor));
    }

    .border-override {
        border-bottom: var(--border-thickness) solid transparent !important;
    }

    .border-light {
        border-bottom: var(--border-thickness) solid rgb(var(--lightSectionBorder));
    }

    .border-second {
        border-bottom: var(--border-thickness) solid rgb(var(--secondColor));
    }

    .border-third {
        border-bottom: var(--border-thickness) solid rgb(var(--thirdColor));
    }

    .border-fourth {
        border-bottom: var(--border-thickness) solid rgb(var(--warningColor));

        [data-theme='gp'] & {
            border-bottom: 4px solid rgb(var(--fifthColor));
        }
    }

    .hidden {
        display: none;
        position: relative;
        z-index: 0;
        pointer-events: none;
        opacity: 0;
    }

    @include mixins.responsive(m, below) {
        margin: var(--standardPadding);
    }
}
