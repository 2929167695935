@use 'layout/basics';
@use 'mixins';

%-left-sidebar {
    @extend %left-side;
    @extend %smallScalablePaddingRight;

    float: left;
    padding-right: var(--largePadding);
}

%-right-sidebar {
    @extend %right-side;
    @extend %smallScalablePaddingLeft;

    float: right;
    padding-left: var(--largePadding);
}

.Sidebar {
    display: block;
    width: calc(100% / 3) !important;
    padding-top: var(--standardPadding);
    box-sizing: border-box;

    &.left-side {
        @extend %-left-sidebar;
    }

    &.right-side {
        @extend %-right-sidebar;
    }

    section {
        margin-bottom: var(--largePadding);
    }

    header {
        line-height: 3.5em;
    }

    h2 {
        display: inline-block;
        margin-left: var(--standardPadding);
        line-height: inherit;
        vertical-align: bottom;
        border-bottom: var(--standardBorderWidth) solid rgb(var(--thirdColor));
    }

    @include mixins.responsive(m, below) {
        &.left-side,
        &.right-side {
            display: none;
        }
    }
}
