@use 'mixins';

$fontSizeMeta: 0.875em;
$lineHeightContent: 1.25em;
$avatarSize: var(--largePadding);

.SimpleComment {
    display: grid;
    position: relative;
    padding: var(--standardPadding);
    overflow: hidden;
    cursor: pointer;
    border-bottom: 1px solid rgb(var(--lightSectionBorder));
    grid-template-areas:
        'avatar content'
        'avatar meta';
    grid-template-columns: $avatarSize 1fr;
    /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
    grid-template-rows: auto auto;
    grid-gap: var(--standardPadding);

    a {
        @extend %no-link-styles;
    }

    .avatar {
        display: inline-block;
        width: $avatarSize;
        height: $avatarSize;
        cursor: pointer;
        color: transparent;
        grid-area: avatar;
    }

    .meta {
        display: block;
        position: relative;
        width: 100%;
        font-size: $fontSizeMeta;
        text-align: left;
        white-space: nowrap;
        color: rgb(var(--lighterTextColor));
        grid-area: meta;
    }

    .reply {
        &-card {
            @extend %card-styles;
        }

        &-header {
            display: flex;
            align-items: baseline;
            position: relative;
            margin-top: 0;
            margin-bottom: var(--tinyPadding);
        }

        &-content {
            margin: 0;
            padding: 0;
            font-size: 1em;
            line-height: $lineHeightContent;

            @include mixins.row-limit(2);
        }
    }

    .user {
        &-name {
            display: inline-block;
            margin-right: var(--tinyPadding);
            font-size: $fontSizeMeta;
            font-weight: 700;
        }

        &-like-count {
            position: relative;
            height: 1em;
            margin-left: var(--smallPadding);
            padding: var(--tinyPadding);
            background-color: rgb(var(--lighterTextColor));
            font-size: $fontSizeMeta;
            overflow: hidden;
            letter-spacing: 0.4px;
            color: rgb(var(--invertedTextColor));
            border-radius: var(--standardRadius);
        }

        &-role {
            margin: auto var(--tinyPadding);
            color: rgb(var(--thirdColor));

            [data-theme='gp'] & {
                color: rgb(var(--fourthColor));
            }
        }
    }
}
