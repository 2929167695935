@use 'mixins';

$gutter-width: var(--standardPadding);
$timeline-point-size: var(--standardPadding);
$offset-content: var(--smallPadding);
$offset-x: calc(-1 * ($gutter-width + $offset-content) / 2);
$border-color: rgb(var(--lightSectionBorder));

%-gutter-line {
    position: absolute;
    top: calc(var(--standardPadding) + $timeline-point-size / 2);
    left: 50%;
    width: 1px;
    height: 100%;
    background: $border-color;
    content: '';
    transform: translateX(-50%);
}

%-inner-point {
    position: absolute;
    content: '';
    background: $border-color;
    border-radius: calc($timeline-point-size / 2);
    outline: 1px solid rgb(var(--invertedTextColor));
    inset: 2px;
}

.TimelineItem {
    padding: 0 var(--standardPadding);

    &:first-of-type {
        .timeline-point {
            &::before {
                background: rgb(var(--secondColor));
            }
        }
    }

    &:last-of-type {
        .content {
            border-bottom: none;
        }
    }

    a {
        display: contents;
        text-decoration: none;
        color: inherit;

        &:hover {
            color: inherit;
        }

        &:visited {
            .heading:not(.highlighted .heading) {
                font-weight: var(--font-weight-semi-bold);
            }

            // White fill for visited timeline point
            .timeline-point:not(.highlighted .timeline-point) {
                background: inherit;

                &::before {
                    background: rgb(var(--background));
                }
            }

            :global(.pro-logo) {
                svg {
                    path:first-of-type {
                        // Visited pro-tag svg path should become gray
                        fill: rgb(var(--lighterTextColor)) !important;
                    }
                }
            }
        }
    }

    .timeline-gutter {
        display: flex;
        position: relative;
        width: $gutter-width;
        margin-right: $offset-content;

        &::before {
            @extend %-gutter-line;
        }
    }

    .timeline-point {
        position: relative;
        width: $timeline-point-size;
        height: $timeline-point-size;
        margin: auto;
        background: $border-color;
        transform: translateX($offset-x);
        border-radius: calc($timeline-point-size / 2);

        &::before {
            @extend %-inner-point;
        }
    }

    .content {
        padding: var(--standardPadding) 0;
        border-bottom: 1px solid $border-color;

        [class*='timestamp'] {
            margin-bottom: 2px;
            transform: translateX($offset-x);
        }
    }

    .heading {
        display: inline;
        margin: 0;
        padding: 0;
        vertical-align: middle;

        [class^='ProTag'],
        [class^='Time'] {
            display: inline-flex;
            margin-right: var(--tinyPadding);
            vertical-align: bottom;
        }

        @include mixins.row-limit(2);
    }

    @include mixins.responsive(m, below) {
        padding: initial;
    }
}
